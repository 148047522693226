<template>
  <div class="bg-black wrapper" v-if="catalogDetails">
    <div class="flex justify-between px-4 mt-6">
      <div>
        <router-link :to="{ name: 'Catalog' }">
          <img :src="require('@/assets/images/logo-dark.webp')" class="w-2/6" />
        </router-link>
      </div>
      <div>
        <button
          @click="logout"
          class="shadow-orange-right-small border border-black bg-nColorYellow rounded-3xl text-black text-center px-4 py-1 uppercase text-base font-ZuumeSemiBold tracking-wider focus:outline-none"
        >
          Logout
        </button>
      </div>
    </div>
    <div id="games" v-if="selectedCat">
      <div class="px-6 pt-6 relative flex items-center gap-2">
        <div>
          <button @click="selectedCat = null">
            <img :src="require('@/assets/images/back.webp')" class="w-8" />
          </button>
        </div>
        <div class="font-ZuumeSemiBold text-nColorYellow text-4xl">
          {{ selectedCat.name }}
        </div>
      </div>
      <div class="m-6">
        <div
          class="mt-2 rounded-xl relative border border-nColorYellow text-nColorYellow shadow-orange-right-small"
          v-for="game in catalogDetails[selectedCat['name']]"
          :key="game.slug"
          @click="goToGame(game.slug)"
        >
          <div class="flex">
            <div class="relative p-2">
              <div class="flex justify-center items-center w-16">
                <img :src="game.icon" width="48" height="28" />
              </div>
            </div>
            <div class="flex items-start pt-1">
              <div class="font-ZuumeSemiBold text-2xl pt-1">
                {{ game.name }}
                <div class="font-InterMedium text-xs pr-2 text-white pb-2">
                  {{ game.desc }}.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="categories" v-else>
      <div
        class="font-ZuumeSemiBold text-nColorYellow text-4xl text-center px-6 pt-6 flex items-center justify-between"
      >
        <div class="flex items-center">
          <div class="text-5xl">Hello!</div>
          <div>
            <img
              src="https://playda.s3.ap-south-1.amazonaws.com/demo/smiley.webp"
              width="50"
              height="50"
            />
          </div>
        </div>
      </div>
      <div class="px-6 text-white font-InterSemiBold">
        Pick a category below to view games.
      </div>
      <div class="m-6">
        <div
          class="mt-4 mb-2 rounded-xl relative bg-nColorOrange"
          v-for="cat in categories"
          :key="cat.name"
          @click.prevent="showGames(cat)"
        >
          <img
            src="https://playda.s3.ap-south-1.amazonaws.com/demo/arrow.webp"
            class="absolute top-4 right-4 w-3"
          />
          <div class="flex w-full triple-border p-1">
            <div class="relative">
              <img :src="cat.icon" width="48" height="48" class="m-1" />
            </div>
            <div class="flex items-start ml-1">
              <div class="font-ZuumeSemiBold text-2xl pt-2">
                {{ cat.name }}
                <div class="font-InterRegular text-sm">
                  {{ getCatLength(cat.name) }}
                  {{ getPluralSuffix(getCatLength(cat.name)) }}.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="grid grid-cols-2 gap-2 p-4">
        <div
          class="flex cols-2 relative"
          v-for="game in catalogDetails"
          :key="game"
        >
          <div
            class="border border-nColorDarkGray w-full p-4 rounded-xl shadow-black-right font-ZuumeRegular text-2xl"
            @click="goToGame(game.slug)"
          >
            {{ game.name }}
          </div>
        </div>
      </div> -->
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";

export default {
  name: "Catalog",
  components: {},
  data() {
    return {
      catalogDetails: null,
      categories: [
        {
          name: "action",
          icon: "https://playda.s3.ap-south-1.amazonaws.com/demo/cat-action.webp",
        },
        {
          name: "puzzle",
          icon: "https://playda.s3.ap-south-1.amazonaws.com/demo/cat-puzzle.webp",
        },
        {
          name: "trivia",
          icon: "https://playda.s3.ap-south-1.amazonaws.com/demo/cat-trivia.webp",
        },
        {
          name: "word",
          icon: "https://playda.s3.ap-south-1.amazonaws.com/demo/cat-word.webp",
        },
        {
          name: "sport",
          icon: "https://playda.s3.ap-south-1.amazonaws.com/demo/cat-sport.webp",
        },
      ],
      selectedCat: null,
    };
  },
  created() {
    ApiService.get(apiResource.getCatalogDetails, {}).then((data) => {
      this.catalogDetails = data.data.data;
    });
  },
  mounted() {},
  computed: {},
  methods: {
    getCatLength(category) {
      return this.catalogDetails[category]
        ? this.catalogDetails[category].length
        : 0;
    },
    getPluralSuffix(count) {
      return count === 1 ? "game" : "games";
    },
    showGames(cat) {
      this.selectedCat = cat;
    },
    goToGame(slug) {
      this.$router.push({
        name: "Game",
        params: {
          game: slug,
        },
      });
    },
    logout() {
      this.$store.dispatch("signOut");
      this.$router.push({
        name: "Login",
      });
    },
  },
};
</script>

<style scoped>
.wrapper {
  background-color: #000000;
  background-image: none;
  width: 100%;
  height: 100dvh;
}
</style>
